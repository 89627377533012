// tslint:disable:no-any
(function () {
    const me = document.querySelector('script[data-name="frontend-components"]');
    const baseUrl = (me && me.getAttribute('data-baseUrl')) || '.';

    const frontendComponentsConfig = {
        paths: {
            '@coconet/accordion': baseUrl + '/frontend-components/Accordion',
            '@coconet/icon': baseUrl + '/frontend-components/Icon',
            '@coconet/infinite-scroll': baseUrl + '/frontend-components/InfiniteScroll',
            '@coconet/loader': baseUrl + '/frontend-components/Loader',
            '@coconet/button': baseUrl + '/frontend-components/Button',
            '@coconet/checkbox': baseUrl + '/frontend-components/Checkbox',
            '@coconet/dropdown': baseUrl + '/frontend-components/Dropdown',
            '@coconet/grid': baseUrl + '/frontend-components/Grid',
            '@coconet/data-grid': baseUrl + '/frontend-components/DataGrid',
            '@coconet/tree-grid': baseUrl + '/frontend-components/TreeGrid',
            '@coconet/menu': baseUrl + '/frontend-components/Menu',
            '@coconet/message': baseUrl + '/frontend-components/Message',
            '@coconet/date-picker': baseUrl + '/frontend-components/DatePicker',
            '@coconet/modal': baseUrl + '/frontend-components/Modal',
            '@coconet/modal-confirmation-dialog': baseUrl + '/frontend-components/ModalConfirmationDialog',
            '@coconet/modal-dialog': baseUrl + '/frontend-components/ModalDialog',
            '@coconet/password-input': baseUrl + '/frontend-components/PasswordInput',
            '@coconet/radio-button': baseUrl + '/frontend-components/RadioButton',
            '@coconet/radio-group': baseUrl + '/frontend-components/RadioGroup',
            '@coconet/select': baseUrl + '/frontend-components/Select',
            '@coconet/tooltip': baseUrl + '/frontend-components/Tooltip',
            '@coconet/notification': baseUrl + '/frontend-components/Notification',
            '@coconet/input': baseUrl + '/frontend-components/Input',
            '@coconet/rich-text-editor': baseUrl + '/frontend-components/RichTextEditor',
            '@coconet/utils': baseUrl + '/frontend-components/Utils',
            '@coconet/textarea': baseUrl + '/frontend-components/Textarea',
            '@coconet/config-selector': baseUrl + '/frontend-components/ConfigSelector',
            '@coconet/tabs': baseUrl + '/frontend-components/Tabs',
            '@coconet/theme': baseUrl + '/frontend-components/Theme',
            '@coconet/stepper': baseUrl + '/frontend-components/Stepper',
            polished: baseUrl + '/frontend-components/vendor/polished',
            react: baseUrl + '/frontend-components/vendor/react',
            'react-dom': baseUrl + '/frontend-components/vendor/react-dom',
            classnames: baseUrl + '/frontend-components/vendor/classnames',
            'react-custom-scrollbars': baseUrl + '/frontend-components/vendor/react-custom-scrollbars',
            'react-number-format': baseUrl + '/frontend-components/vendor/react-number-format',
            popper: baseUrl + '/frontend-components/vendor/popper.js',
            'react-popper': baseUrl + '/frontend-components/vendor/react-popper',
            'react-transition-group': baseUrl + '/frontend-components/vendor/react-transition-group',
            emotion: baseUrl + '/frontend-components/vendor/emotion',
            'react-beautiful-dnd': baseUrl + '/frontend-components/vendor/react-beautiful-dnd'
        },
        map: {
            'react-popper': {
                'popper.js': 'popper'
            }
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (!(window as any).requirejs) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).requirejs = frontendComponentsConfig;
    } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).requirejs.config(frontendComponentsConfig);
    }
})();
